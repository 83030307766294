export const menuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: "/pemohon/dashboard",
    },
    {
        id: 2,
        label: "Daftar ID Izin",
        icon: "bx-list-plus",
        link: "/pemohon/daftar-id-izin",
    },
    {
        id: 3,
        label: "Daftar Permohonan",
        icon: "bx-list-ul",
        link: "/pemohon/daftar-permohonan",
    },
    {
        id: 25,
        label: "Laporan",
        icon: "bx-customize",
        subItems: [
            {
                id: 26,
                label: "Laporan Permohonan Selesai",
                link: "/404",
                parentId: 25,
            },
        ],
    },
];
