<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <a data-v-77cf0626="" href="#" target="_blank">Bayer</a> <span data-v-77cf0626="" class="ml-1">&copy; {{ new Date().getFullYear() }} Bayer</span>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block"><span data-v-a6632ae4="" class="mr-1">Powered by</span> <a data-v-a6632ae4="" href="http://pvtpp.setjen.pertanian.go.id/cms2017/" target="_blank">Bayer</a></div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end footer -->
</template>
